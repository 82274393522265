import { List_Logs } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllBmsLogs = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetAllBMSLogs: [],
      GetAllBMSLogsFilter: [],
    };
  },
  methods: {
    async GetAllBMSLogsMethod() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(List_Logs, {
            input: {},
          })
        );
        this.GetAllBMSLogs = JSON.parse(result.data.List_Logs).data.items;
        this.GetAllBMSLogsFilter = JSON.parse(result.data.List_Logs).data.items;
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.GetAllBMSLogs = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
