<template>
  <div>
    <Overlay :overlay="overlay" />
    <v-card class="ma-1" flat elevation="0">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="6" xs="12">
          <v-toolbar dense class="elevation-0">
            <div class="PrimaryFontColor heading1">BMS Logs</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" sm="12" md="6" xs="12">
          <v-toolbar dense class="elevation-0">
            <v-select
              dense
              outlined
              class="mt-6 FontSize field_label_size field_height maxWidth"
              label="Log Type"
              v-model="ActionType"
              :items="ActionTypeItems"
              item-text="text"
              item-value="value"
            ></v-select>
            <v-text-field
              dense
              outlined
              label="Search"
              class="mt-6 ml-2 FontSize field_label_size field_height"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table
          dense
          :search="search"
          :headers="headers"
          :items="GetAllBMSLogs"
          :no-data-text="noDataText"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page="100"
          fixed-header
          :height="TableHeight"
          :footer-props="{
            'items-per-page-options': [100, 200, 500, 'ALL'],
          }"
        >
          <template v-slot:[`item.log_action`]="{ item }">
            <div class="FontSize">{{ item.log_action.split("-")[0] }}</div>
          </template>
          <template v-slot:[`item.log_type`]="{ item }">
            <div class="FontSize">{{ item.log_type.replace("_", " ") }}</div>
          </template>
          <template v-slot:[`item.logged_by`]="{ item }">
            <div class="FontSize">{{ item.logged_by.replace("_", " ") }}</div>
          </template>
          <template v-slot:[`item.logged_on`]="{ item }">
            <span class="FontSize">{{
              new Date(item.logged_on * 1000).toLocaleString("en-GB")
            }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { List_Logs } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import { GetAllBmsLogs } from "@/mixins/GetAllBmsLogs.js";
import Overlay from "@/components/Extras/Overlay.vue";
export default {
  mixins: [GetAllBmsLogs],
  components: {
    Overlay,
  },
  data: () => ({
    TableHeight: 0,
    search: "",
    noDataText: "",
    ActionType: "ALL",
    sortBy: "logged_on",
    overlay: false,
    sortDesc: true,
    isLoading: false,
    LogsList: [],
    GetAllBMSLogs: [],
    GetAllBMSLogsFilter: [],
    headers: [
      { text: "Log Action", value: "log_action" },
      { text: "Log Type", value: "log_type" },
      { text: "Logged By", value: "logged_by" },
      { text: "Logged On", value: "logged_on" },
    ],
    ActionTypeItems: [
      { text: "ALL", value: "ALL" },
      { text: "ORGANIZATION MANAGEMENT", value: "ORGANIZATION_MANAGEMENT" },
      { text: "CATEGORY MANAGEMENT", value: "CATEGORY_MANAGEMENT" },
      { text: "USER MANAGEMENT", value: "USER_MANAGEMENT" },
      { text: "BMS SERVICE MANAGEMENT", value: "BMS_SERVICE_MANAGEMENT" },
    ],
  }),
  watch: {
    ActionType(val) {
      if (val == "ALL") {
        this.GetAllBMSLogs = this.GetAllBMSLogsFilter;
      } else {
        this.GetAllBMSLogs = this.GetAllBMSLogsFilter.filter(
          (item) => item.log_type == val
        );
      }
      if (this.GetAllBMSLogs.length == 0) {
        this.noDataText = `No logs Found With Log Type ${val.replaceAll(
          "_",
          " "
        )}`;
      }
    },
  },
  mounted() {
    this.GetAllBMSLogsMethod();
    this.TableHeight = window.innerHeight - 200;
  },
  methods: {
    async getLists(val) {
      this.isLoading = true;
      try {
        this.OrganizationsList = [];
        let result = await API.graphql(
          graphqlOperation(List_Logs, {
            input: {},
          })
        );
        if (val == "ALL") {
          var self = this;
          var storearray = JSON.parse(result.data.List_Logs);
          self.LogsList = storearray.data.items;
          self.ActionTypeItemsNew = self.LogsList.filter(
            (action) => action.log_type
          );
          self.ActionTypeItems = self.ActionTypeItemsNew.map(
            (item) =>
              item.log_type.split("_")[0] + " " + item.log_type.split("_")[1]
          );
          self.ActionTypeItems.unshift("ALL");
          this.isLoading = false;
          this.$forceUpdate();
        } else {
          storearray = JSON.parse(result.data.List_Logs);
          this.LogsListNew = storearray.data.items;
          this.LogsList = this.LogsListNew.filter(
            (action) =>
              action.log_type.split("_")[0] +
                " " +
                action.log_type.split("_")[1] ==
              val
          );
          this.isLoading = false;
          this.$forceUpdate();
        }
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },
  },
};
</script>

<style>
.gradient-btn {
  background: -webkit-linear-gradient(#7b046c 40%, #bd58b0 60%);
  -webkit-text-fill-color: transparent;
}
.gradient-bg {
  background: -webkit-linear-gradient(145deg, #bd58b0 0%, #7b046c 100%);
  background: -moz-linear-gradient(145deg, #bd58b0 0%, #7b046c 100%);
  background: -o-linear-gradient(145deg, #bd58b0 0%, #7b046c 100%);
  background: -ms-linear-gradient(145deg, #bd58b0 0%, #7b046c 100%);
  background: linear-gradient(145deg, #bd58b0 0%, #7b046c 100%);
}
.gradient-bg2 {
  background: -webkit-linear-gradient(145deg, #7b046c 0%, #bd58b0 100%);
  background: -moz-linear-gradient(145deg, #7b046c 0%, #bd58b0 100%);
  background: -o-linear-gradient(145deg, #7b046c 0%, #bd58b0 100%);
  background: -ms-linear-gradient(145deg, #7b046c 0%, #bd58b0 100%);
  background: linear-gradient(145deg, #7b046c 0%, #bd58b0 100%);
}
</style>
